<template>
  <the-base-table
    ref="baseTable"
    url="bonus_list/"
    singularName="Solicitud de Bonificación"
    pluralName="Solicitudes de Bonificación"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="BonusEdit"
    :objectDefault="BonusDefault"
    :showActionsColumn="true"
    :create="false"
    :edit="false"
    :remove="false"
  >
    <template v-slot:form="{ isEditing }">
      <bonus-form
        :bonus="BonusEdit"
        :is-editing="isEditing"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>

    <template v-slot:toolbar-prepend>
      <v-flex class="ma-2" xs12 md2 lg2>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              block
              color="accent"
              v-bind="attrs"
              v-on="on"
              :loading="loadingFile"
              title="Descargar informe"
              @click="CreateStateDialog()"
            >
              <v-icon class="mx-2">mdi-cloud-download</v-icon>Informe
            </v-btn>
          </template>
          <span>Descargar informe</span>
        </v-tooltip>
      </v-flex>

      <v-dialog v-model="isOpenDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="headline">Descarga de Reporte</v-card-title>
          <validation-observer v-slot="{ handleSubmit }" ref="observerTwo">
            <v-form ref="form" @submit.prevent="handleSubmit(toDownload)">
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md6>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Fecha desde"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :value="computedDateFormattedFrom"
                              label="Fecha desde"
                              hint="dd-mm-aaaa"
                              persistent-hint
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          locale="es-CL"
                          v-model="date_from"
                          no-title
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Fecha hasta"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :value="computedDateFormattedTo"
                              label="Fecha hasta"
                              hint="dd-mm-aaaa"
                              persistent-hint
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          locale="es-CL"
                          v-model="date_to"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="stopEditingReport()"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="success darken-1"
                  :loading="isDownloading"
                  text
                  type="submit"
                  >Descargar</v-btn
                >
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:toolbar-append>
      <v-container>
        <v-layout wrap>
          <v-flex xs12 sm6 md2>
            <v-text-field
              name="id"
              v-model="filter.id"
              :value="filter.id"
              label="ID"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="year"
              :items="years"
              :loading="loadingYear"
              v-model="filter.year"
              :value="filter.year"
              label="Año de validez"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="healthCoverage"
              :items="coverages"
              :loading="loadingCoverage"
              v-model="filter.coverage"
              :value="filter.coverage"
              label="Previsión Salud"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="msState"
              :items="states"
              :loading="loadingState"
              v-model="filter.state"
              :value="filter.state"
              label="Estado solicitud"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="msUser"
              :items="users"
              :loading="loadingUser"
              v-model="filter.user"
              :value="filter.user"
              label="Funcionario"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="msCampus"
              :items="campuss"
              :loading="loadingCampus"
              v-model="filter.campus"
              :value="filter.campus"
              label="Campus"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td class="text-center">{{ item.order_number }}</td>
      <td class="text-center">{{ item.get_affiliate_display_name }}</td>
      <td class="text-center">{{ item.get_coverage_display }}</td>
      <td class="text-center">{{ item.requested_amount | currency }}</td>
      <td class="text-center">{{ item.coverage_amount | currency }}</td>
      <td class="text-center">{{ item.created_at }}</td>
      <td class="text-center">
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Aprobada'"
          text
          type="success"
          >{{ item.get_last_state_display }}</v-alert
        >
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Rechazada'"
          text
          type="error"
          >{{ item.get_last_state_display }}</v-alert
        >
        <div
          v-if="
            item.get_last_state_display != 'Aprobada' &&
            item.get_last_state_display != 'Rechazada' &&
            item.get_last_state_display != 'Pagado'
          "
        >
          {{ item.get_last_state_display }}
        </div>
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Pagado'"
          text
          type="success"
          >{{ item.get_last_state_display }}</v-alert
        >
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <v-btn
        rounded
        color="info darken-2"
        class="mx-2"
        @click="showItem(item)"
        dark
        ><v-icon>mdi-information</v-icon></v-btn
      >
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import BonusForm from '@/components/bonus_admin/BonusForm'
import Utils from '@/utils'
export default {
  data: () => ({
    date_from: null,
    menu: false,
    menu2: false,
    date_to: null,
    years: [],
    coverages: [],
    states: [],
    users:[],
    campuss: [],
    loadingCampus : true,
    loadingUser: true,
    loadingState: true,
    loadingCoverage: true,
    loadingYear: true,
    loadingFile: false,
    isOpenDialog: false,
    isDownloading: false,
    filter: {
      year: new Date().getFullYear(),
      state: null,
      coverage: null,
      user : null,
      campus : null
    },
    BonusEdit: {
      bonus_amount: 0,
      payment_method: 0,
    },
    BonusDefault: {
      id: -1,
      bonus_amount: 0,
      payment_method: 0,
    },
    headers: [
      { text: 'ID', name: 'id', value: 'id', align: 'center' },
      { text: 'Nº de orden', value: 'order_number', align: 'center' },
      {
        text: 'Afiliado solicitante',
        value: 'get_affiliate_display_name',
        align: 'center',
      },
      { text: 'Previsión', value: 'get_coverage_display', align: 'center' },
      {
        text: 'Valor Bono/Boleta ($)',
        value: 'requested_amount',
        align: 'center',
      },
      {
        text: 'Valor seguro/cobertura salud ($)',
        value: 'coverage_amount',
        align: 'center',
      },
      { text: 'Fecha de solicitud', value: 'created_at', align: 'center' },
      {
        text: 'Estado solicitud',
        value: 'get_last_state_display',
        align: 'center',
      },
    ],
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filters() {
      return this.filter
    },
    computedDateFormattedFrom() {
      return Utils.formatDate(this.date_from)
    },
    computedDateFormattedTo() {
      return Utils.formatDate(this.date_to)
    },
  },
  mounted() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('bonus_list/users'))
        .then((data) => {
          data.data.forEach((User) => {
            this.users.push({
              value: User.rut,
              text: User.name,
            })
          })
          this.users.push({
            value: null,
            text: 'Todos',
          })
          this.loadingUser = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('bonus_list/campus'))
        .then((data) => {
          data.data.forEach((C) => {
            this.campuss.push({
              value: C.name,
              text: C.name,
            })
          })
          this.campuss.push({
            value: null,
            text: 'Todos',
          })
          this.loadingCampus = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('bonus_list/years'))
        .then((data) => {
          data.data.forEach((Year) => {
            this.years.push({
              value: Year.year,
              text: Year.year,
            })
          })
          this.years.push({
            value: 0,
            text: 'Todos',
          })
          this.loadingYear = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('bonus_list/health_coverage'))
        .then((data) => {
          data.data.forEach((Coverage) => {
            this.coverages.push({
              value: Coverage.id,
              text: Coverage.coverage,
            })
          })
          this.coverages.push({
            value: null,
            text: 'Todos',
          })
          this.loadingCoverage = false
        })
        .catch((error) => console.log(error))
      this.axios
        .get(this.$api('bonus_list/states'))
        .then((data) => {
          data.data.forEach((State) => {
            this.states.push({
              value: State.id,
              text: State.state,
            })
          })
          this.states.push({
            value: null,
            text: 'Todos',
          })
          this.loadingState = false
        })
        .catch((error) => console.log(error))
    }
  },
  methods: {
    showItem(item) {
      this.$router.push({ name: 'bonus.show', params: { id: item.id } })
    },
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    stopEditingReport() {
      this.isOpenDialog = false
      this.$refs.observerTwo.reset()
      this.date_from = this.date_to = null
    },
    error(event) {
      console.log(event)
    },
    CreateStateDialog() {
      this.isOpenDialog = true
    },
    toDownload() {
      this.isDownloading = true
      return this.axios
        .post(
          this.$api('bonus_list/bonus_report/'),
          {
            date_from: this.date_from,
            date_to: this.date_to,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then(({ data }) => {
          this.forceFileDownload(data)
          this.$refs.form.reset()
          this.isOpenDialog = false
          this.$store.dispatch(
            'message/success',
            'El recurso ha sido creado con éxito'
          )
        })
        .catch((error) => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.isDownloading = false
          })
        })
    },
    forceFileDownload(data) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'reporte_bonificaciones_' +
          new Date().toJSON().slice(0, 10).replace(/-/g, '/') +
          '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    },
  },
  components: {
    TheBaseTable,
    BonusForm,
  },
}
</script>
