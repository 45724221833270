<template>
  <div id="bonusIndex">
    <bonus-admin-table v-if="isAdmin" />
    <bonus-affiliate-table v-if="!isAdmin" />
  </div>
</template>

<script>
import BonusAdminTable from '@/components/bonus_admin/BonusTable'
import BonusAffiliateTable from '@/components/bonus_affiliate/BonusTable'
import Utils from '@/utils'
export default {
  components: { BonusAdminTable, BonusAffiliateTable },
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return true
      }
      return false
    },
  },
}
</script>
