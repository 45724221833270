<template>
  <the-base-table
    ref="baseTable"
    url="affiliate_bonus_list/"
    singularName="Solicitud de Bonificación"
    pluralName="Mis Solicitudes de Bonificación"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="BonusEdit"
    :objectDefault="BonusDefault"
    :showActionsColumn="true"
    :create="true"
    :edit="false"
    :remove="false"
  >
    <template v-slot:form="{ isEditing }">
      <bonus-form
        :is-editing="isEditing"
        :bonus="BonusEdit"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>

    <template v-slot:toolbar-append>
      <v-container>
        <v-layout wrap>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="year"
              :items="years"
              :loading="loadingYear"
              v-model="filter.year"
              :value="filter.year"
              label="Año de validez"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="healthCoverage"
              :items="coverages"
              :loading="loadingCoverage"
              v-model="filter.coverage"
              :value="filter.coverage"
              label="Previsión Salud"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="msState"
              :items="states"
              :loading="loadingState"
              v-model="filter.state"
              :value="filter.state"
              label="Estado solicitud"
            ></v-autocomplete>
          </v-flex>
          <v-spacer />
          <v-flex v-if="filter.state == aprovedState" class="ma-2" xs12 sm6 md2>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  block
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loadingFile"
                  @click="CreateStateDialog()"
                >
                  <v-icon class="mx-2">mdi-cloud-download</v-icon>Cartola
                </v-btn>
              </template>
              <span>Descargar cartola solicitudes aprobadas</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-container>

      <v-dialog v-model="isOpenDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="headline"
            >Descarga cartola resumen solicitudes aprobadas</v-card-title
          >
          <validation-observer v-slot="{ handleSubmit }" ref="observerTwo">
            <v-form ref="form" @submit.prevent="handleSubmit(toDownload)">
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md6>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Fecha desde"
                            rules="required|before:@Fecha_hasta"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :value="computedDateFormattedFrom"
                              label="Fecha desde"
                              hint="dd-mm-aaaa"
                              persistent-hint
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          locale="es-CL"
                          v-model="date_from"
                          no-title
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Fecha_hasta"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :value="computedDateFormattedTo"
                              label="Fecha hasta"
                              hint="dd-mm-aaaa"
                              persistent-hint
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          locale="es-CL"
                          v-model="date_to"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="stopEditingReport()"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="success darken-1"
                  :loading="isDownloading"
                  text
                  type="submit"
                  >Descargar</v-btn
                >
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td class="text-center">{{ item.order_number }}</td>
      <td class="text-center">{{ item.get_coverage_display }}</td>
      <td class="text-center">{{ item.requested_amount | currency }}</td>
      <td class="text-center">{{ item.coverage_amount | currency }}</td>
      <td class="text-center">{{ item.return_amount | currency }}</td>
      <td class="text-center">{{ item.created_at }}</td>
      <td class="text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="warning"
              dark
              v-bind="attrs"
              v-on="on"
              v-if="
                item.get_last_state_display === draftState ||
                  item.get_last_state_display === requestClarificationState
              "
              >
            </v-icon>
          </template>
          <span v-if="item.get_last_state_display === draftState"
            >Complete información y envie su solicitud</span
          >
          <span v-if="item.get_last_state_display === requestClarificationState"
            >Se le ha solicitado aclarar información</span
          >
        </v-tooltip>
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Aprobada'"
          text
          type="success"
          >{{ item.get_last_state_display }}</v-alert
        >
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Rechazada'"
          text
          type="error"
          >{{ item.get_last_state_display }}</v-alert
        >
        <div
          v-if="
            item.get_last_state_display != 'Aprobada' &&
            item.get_last_state_display != 'Rechazada' &&
            item.get_last_state_display != 'Pagado'
          "
        >
          {{ item.get_last_state_display }}
        </div>
        <v-alert
          class="mt-3"
          v-if="item.get_last_state_display == 'Pagado'"
          text
          type="success"
          >{{ item.get_last_state_display }}</v-alert
        >
      </td>
    </template>
    <template v-slot:actions="{ item }">
      <v-btn
        rounded
        color="info darken-2"
        class="mx-2"
        @click="showItem(item)"
        dark
        ><v-icon>mdi-information</v-icon></v-btn
      >
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import BonusForm from '@/components/bonus_affiliate/BonusForm'
import Utils from '@/utils'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
export default {
  data: () => ({
    draftState: Utils.BonusStates.DRAFT.state,
    requestClarificationState: Utils.BonusStates.REQUEST_CLARIFICATION.state,
    aprovedState: Utils.BonusStates.APPROVED.id,
    years: [],
    coverages: [],
    states: [],
    date_from: null,
    menu: false,
    menu2: false,
    date_to: null,
    loadingState: true,
    loadingCoverage: true,
    loadingYear: true,
    loadingFile: false,
    isOpenDialog: false,
    isDownloading: false,
    filter: {
      year: null,
      state: null,
      coverage: null,
    },
    BonusEdit: {
      id: null,
      bonus_amount: 0,
      payment_method: 0,
    },
    BonusDefault: {
      id: -1,
      order_number: '',
      order_date: new Date().toISOString().substr(0, 10),
      service: '',
      coverage: '',
      dependents: [],
      requested_amount: 0,
      payment_method: 0,
    },
    headers: [
      { text: 'ID', name: 'id', value: 'id', align: 'center' },
      { text: 'Nº de orden', value: 'order_number', align: 'center' },
      { text: 'Previsión', value: 'get_coverage_display', align: 'center' },
      {
        text: 'Valor Bono/Boleta ($)',
        value: 'requested_amount',
        align: 'center',
      },
      {
        text: 'Valor seguro/cobertura salud ($)',
        value: 'coverage_amount',
        align: 'center',
      },
      {
        text: 'Monto devuelto ($)',
        value: 'return_amount',
        align: 'center',
      },
      { text: 'Fecha de solicitud', value: 'created_at', align: 'center' },
      {
        text: 'Estado solicitud',
        value: 'get_last_state_display',
        align: 'center',
      },
    ],
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filters() {
      return this.filter
    },
    computedDateFormattedFrom() {
      return Utils.formatDate(this.date_from)
    },
    computedDateFormattedTo() {
      return Utils.formatDate(this.date_to)
    },
    ulagos_logo_izq() {
      return require('@/assets/logo_ulagos_traz_RGB.jpg')
    },
  },
  mounted() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('affiliate_bonus_list/years'))
        .then(data => {
          data.data.forEach(Year => {
            this.years.push({
              value: Year.year,
              text: Year.year,
            })
          })
          this.years.push({
            value: null,
            text: 'Todos',
          })
          this.loadingYear = false
        })
        .catch(error => console.log(error))
      this.axios
        .get(this.$api('affiliate_bonus_list/health_coverage'))
        .then(data => {
          data.data.forEach(Coverage => {
            this.coverages.push({
              value: Coverage.id,
              text: Coverage.coverage,
            })
          })
          this.coverages.push({
            value: null,
            text: 'Todos',
          })
          this.loadingCoverage = false
        })
        .catch(error => console.log(error))
      this.axios
        .get(this.$api('affiliate_bonus_list/states'))
        .then(data => {
          data.data.forEach(State => {
            this.states.push({
              value: State.id,
              text: State.state,
            })
          })
          this.states.push({
            value: null,
            text: 'Todos',
          })
          this.loadingState = false
        })
        .catch(error => console.log(error))
    }
  },
  methods: {
    showItem(item) {
      this.$router.push({ name: 'bonus.show', params: { id: item.id } })
    },
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    error(event) {
      console.log(event)
    },
    gotoDetail() {
      console.log(this.$refs.baseForm)
    },
    CreateStateDialog() {
      this.isOpenDialog = true
    },
    stopEditingReport() {
      this.isOpenDialog = false
      this.$refs.observerTwo.reset()
      this.date_from = this.date_to = null
    },
    toDownload() {
      this.isDownloading = true
      return this.axios
        .post(
          this.$api('affiliate_bonus_list/bonus_aproved_report/'),
          {
            date_from: this.date_from,
            date_to: this.date_to,
          }
          // {
          //   responseType: 'arraybuffer',
          // }
        )
        .then(({ data }) => {
          this.createPDF(data)
          this.$refs.form.reset()
          this.isOpenDialog = false
          this.$store.dispatch(
            'message/success',
            'El recurso ha sido creado con éxito'
          )
        })
        .catch(error => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.isDownloading = false
          })
        })
    },
    forceFileDownload(data) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'cartola_bonificaciones_aprobadas_' +
          new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, '/') +
          '.pdf'
      )
      document.body.appendChild(link)
      link.click()
    },
    createPDF(data) {
      console.log('creando pdf')
      var doc = new JsPDF({ putOnlyUsedFonts: true })
      const title = 'Cartola de bonificaciones aprobadas'
      doc.addImage(this.ulagos_logo_izq, 'PNG', 14, 15, 45, 25)
      doc.setFontSize(18)
      var textWidth =
        (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2
      doc.text(title, textOffset, 50)
      doc.setFontSize(10)
      doc.setTextColor(100)
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize
      var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      var affiliateName = doc.splitTextToSize(
        'Nombre Afiliado: ' + data.affiliate_name + '.',
        pageWidth - 35,
        {}
      )
      doc.text(affiliateName, 14, 60)
      var affiliateRun = doc.splitTextToSize(
        'RUT Afiliado: ' +
          (data.affiliate_run === null
            ? 'sin información.'
            : data.affiliate_run),
        pageWidth - 35,
        {}
      )
      doc.text(affiliateRun, 14, 65)
      var reportInfo = doc.splitTextToSize(
        'Cartola generada para periodo: ' +
          Utils.formatDate(data.date_from) +
          ' - ' +
          Utils.formatDate(data.date_to),
        pageWidth - 35,
        {}
      )
      doc.text(reportInfo, 14, 75)
      doc.autoTable({
        head: [
          {
            order_number: 'Num. de orden',
            get_coverage_display: 'Previsión',
            requested_amount: 'Valor Boleta/Bono',
            coverage_amount: 'Valor cobertura seguro',
            bonus_amount: 'Monto bonificado',
            order_date: 'Fecha de orden',
            created_at: 'Fecha ingreso solicitud',
          },
        ],
        body: this.bodyRows(data.bonus),
        startY: 85,
        showHead: 'firstPage',
      })
      // doc.text(text, 14, doc.lastAutoTable.finalY + 10)
      const pdfName =
        'cartola_bonificaciones_aprobadas_' +
        data.date_from +
        '_' +
        data.date_to
      doc.save(pdfName + '.pdf')
    },
    bodyRows(obj) {
      var body = []
      for (const i in obj) {
        body.push({
          order_number: obj[i].order_number,
          get_coverage_display: obj[i].get_coverage_display,
          requested_amount: this.$options.filters.currency(
            obj[i].requested_amount
          ),
          coverage_amount: this.$options.filters.currency(
            obj[i].coverage_amount
          ),
          bonus_amount: this.$options.filters.currency(obj[i].return_amount),
          order_date: obj[i].order_date,
          created_at: obj[i].created_at,
        })
      }
      return body
    },
  },
  components: {
    TheBaseTable,
    BonusForm,
  },
}
</script>
